.missingfincacust{
    display: flex;
    justify-content: start;
    background-color: #FFF0F0;
    padding: 12px;
    gap: 16px;
    margin-top: 20px;
    border-radius: 6px;
}

.missingcusttext{
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0px;
    color: #292929;
}

.Vector{
    height: 17px;
    width: 17px;
    margin-bottom: 4px;
    margin-right: 5px;
}

.missingfincainfo{
  margin-left: 5px;
  width: 17px;
  height: 17px;
  margin-bottom: 3px;
}

/* --------------Finca Popup ----------------------- */
.alertmessage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
  }
  
  .alertcontent {
    background: white;
    border-radius: 5px;
    text-align: center;
    width:  599px
  }
  
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    padding: 20px 20px 10px 20px;
  }
  .headertext{
    font-family: 'VodafoneRg-Regular';
    font-weight: 700;
    font-size: 24px;
    line-height: 27.5px;
    letter-spacing: 0px;
    color: #333333;
  }
  
  .close{
    width: 16px;
    height: 16px;
  }

  .tabledata tr:hover {
    outline: 1px solid #979797;
    box-shadow: 2px 2px 12px 0px #0000001A;
  }
  
  .infomsg{
    margin: 0 20px;
    background-color: #FFEBCD;
    padding: 5px;
    text-align: left;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.07px;
    letter-spacing: 0px;
    font-family: 'VodafoneRg-Regular';
    color: #333333;
  }
  
  .tableparent{
    margin: 20px;
  }


  /* ----------table----------- */
  .tableContainer {
    border: 2px solid #CCCCCC;
    border-radius: 6px;
    width: 100%;
    max-height: 300px;
    /* height: 300px; */
  }

  .customTable {
    --bs-table-bg: initial !important;
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
    border-color: #ddd !important;
  }

  .tableHeader {
    background-color: #CCCCCC !important;
    height: 45px;
  }
  
  .tableHeaderCell {
    padding: 0; 
    border-right: 2px solid #E6E6E6;
    justify-content: start;
    display: flex;
    align-items: center;
    height: 45px;
  }
  
  .tableHeaderCell:last-child {
    border-right: none;
  }
  
  
  .headerContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    text-align: left;
    text-decoration-skip-ink: none;
    font-family: 'VodafoneRg-Regular';
    font-size: 18px;
    line-height: 20.63px;
    letter-spacing: 0px;
    font-weight: 700;
    color: #333333;
  }
  
  .tableCell {
    border-right: 2px solid #E6E6E6;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: start;
    font-family: 'VodafoneRg-Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
    height: 45px;
    align-items: center;
    color: #333333 !important;
  }

  .tableCell:last-child {
    border-right: none;
  }

  .evenRow {
    background-color: #FFFFFF;
  }
  
  .oddRow {
    background-color: #F4F4F4;
  }

  .tableCell:last-child {
    border-right: none;
  }

  .arrowdown{
    transform: rotate(180deg);
    height: 20px;
    float: right;
    cursor: pointer;
  }

  .nodata{
    /* border-style: none !important; */
    margin-top: 10px;
  }

  .filterdata{
    text-align: center;
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    border-style: none !important;
  }