/*CSS*/
.fieldContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 20px;
}

.serviceDetailContainer {
    display: flex;
    background-color: #fff;
    margin-bottom: 20px;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #999999;
    border-radius: 6px;
}
.attachlogo{
    height: 36px;
    width: 36px;
}

/* .topSection {
    border-bottom: 1px solid #CCCCCC;
} */

.requestorDetailsDiv {
    display: flex;
    width:25%;
    /* flex-direction: column; */
    /* flex:1; */
    /* padding-left: 10px; */
}

.border{
   border-right:1px solid #cccccc;
}

.paddingleft{
    padding-left: 25px;
}


.topSectionDiv,
.bottomSectionDiv {
    display: flex;
    width: 100%;
    padding-bottom: 12px;
    flex-flow: wrap;
}

.requestorDetailsDiv span,
.serviceDetailDiv span {
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    /* font-style: normal; */
    font-weight: 400;
    line-height: 28px;
}

.serviceDetailDiv span:nth-child(2n),
.requestorDetailsDiv span:nth-child(2n) {
    font-weight: 700;
    font-family: "VodafoneRg-Regular";
    margin-left: 4px;
    font-size: 16px;
    line-height: 28px;
    color: #333333;
}

.infolabel{
  color: #666666;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  min-width: 145px;
}
/* .serviceDescriptionDiv > div{
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    width: 145px;
} */

.serviceDetailDiv {
    display: flex;
    /* flex-direction: column; */
    width: 25%;
    /* padding-bottom: 20px; */
    /* border-right: 1px solid #cccccc;
    padding-left: 10px; */

}

.commentSection {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
}

.commentHeader {
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 24px;
    font-style: normal;
}

/*.attachmentHeader {
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-style: normal;
}*/

.commentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.attachmentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.attachmentDiv {
    width: 250px;  
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid #666666;
    align-items: center;
    gap:10px;
    min-height: 50px;  
}

.attachmentName {
    flex: 2;
   /* display: flex;
    flex-direction: row;*/
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    font-style: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
}

.attachmentHeader {

    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-style: normal;
    width: 100%;
    padding-bottom: 8px;

}

.attachmentContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:20px;
}

img.commentIcon{
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.serviceDetailHeader { 
   display: flex;
   justify-content: space-between;
   margin-top: 20px;
   margin-bottom: 10px;
}
.serviceDetailHeader div:nth-child(1) { 
    /* flex: 2; */
    color: #333333; 
    font-family: "VodafoneRg-Regular";
    font-size: 24px;
    /* font-style: normal; */
    /* font-weight: 700; */
    line-height: 24px;
    display: flex;
    margin-bottom: 10px;
}
.demandreqId{
    margin-right: 10px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 3px;
}

img.download {
    margin-right: 10px;
    cursor: pointer;
}

.commentMainContainer { 
    display: flex;
    flex-direction: column;
    gap:16px;
}
/*.serviceDetailHeader img {
    margin-left: 30px;
    cursor: pointer;
}*/
.serviceAttachmentDiv { 
    display: flex;
    flex-direction: row;
    gap:10px;
    padding:10px 0px 10px 0px

}
.infotext{
  font-weight: 400 !important;
  font-family: "VodafoneRg-Regular";
  /* margin-left: 5px; */
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  /* background-color: #979797; */
  /* cursor: all-scroll; */
 margin-bottom: 10px;
  border: 1px solid #999999;
  border-radius: 6px;
  padding: 2px 10px;
  max-height: 88px;
  overflow: auto;
  width: 100%;
}
.infotexts{
  font-weight: 400 !important;
  font-family: "VodafoneRg-Regular";
  /* margin-left: 5px; */
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  margin-bottom: 10px;
  max-height: 88px;
  overflow: auto;
  width: 100%;
}
.info{
  font-weight: 400 !important;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
}
/* .serviceDescriptionDiv div:nth-child(2n),
.serviceDescriptionDiv > .requestorDetailsDiv div:nth-child(2n) {
    font-weight: 700;
    font-family: "VodafoneRg-Regular";
    margin-left: 5px;
    font-size: 16px;
    line-height: 28px;
    color: #333333;
} */

.serviceDescriptionDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}
.infotextflex{
  display: flex;
  flex-direction: column;
}
.iconsContainer {
    display: flex;
}
.editIcon{
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-left: 10px;
}
/* Previous configuration */
.PreviousHeader {
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 24px;
    border-top: 1px solid #CCCCCC;
    padding-top: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    /* padding-top: 16px; */
}
.previousMainContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 16px;
  padding: 10px 0px 0px 0px;
  /* border-bottom: 1px solid #CCCCCC; */
  /* margin-bottom: 10px; */
}
.previousContainer {
   
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-weight: 400;
    color: #666666;
}
.previousContainer span{
   
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
    font-weight: 700;
    color: #333333;
}

.fileDownloadLoader{
 width:32px;
 height:32px
}

.back{
  width: 24px;
  margin: auto 4px 4px -5px;
  cursor: pointer;
}

.changestatus{
font-family: "VodafoneRg-Regular";
font-size: 16px;
font-weight: 400;
line-height: 18.37px;
text-align: right;
text-decoration: underline;
text-decoration-style: solid;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #E60000;
cursor: pointer;
}

.listhead {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

.initaltext {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    font-family: "VodafoneRg-Regular";
  }

.addcomment{
    height: 46px;
    width: 100%;
    border: 1px solid #979797;
    border-radius: 6px;
    font-size: 20px;
    padding: 0px 10px;
    color: #FFFFFF;
    background-color: #666666;
    font-family: "VodafoneRg-Regular";
  }

  .loader{
    min-height: 150px;
    display: flex;
    justify-content: center;
  }

  .commentContainer {
    border: 1px solid #979797;
    border-radius: 6px;
    padding: 10px;
    background-color: #ffffff;
    margin: 10px 0px;
  }

  .initalshead {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .initatname {
    font-weight: 700;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    line-height: 17.6px;
  }

  .inital {
    height: 36px;
    width: 36px;
    font-size: 16px;
    color: #ffffff;
    border-radius: 25px;
    line-height: 36px;
    margin-right: 8px;
    text-align: center;
    font-family: "VodafoneRg-Regular";
    background-color: #007C92;
  }

  .initatname {
    font-weight: 700;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
  }

  .infoattach {
    height: 24px;
    width: 24px;
    margin-left: 10px;
    padding-bottom: 3px;
  }

  .attachments{
    color: #666666;
    font-size: 16px;
    padding-right: 20px;
    font-family: "VodafoneRg-Regular";
    line-height: 28px;
  }
  .date {
    color: #000000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    padding-left: 3px;
    word-wrap: break-word;
  }

  .comment {
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
    color: #333333;
    margin-bottom: 10px;
    line-height: 24px;
    font-weight: 400;
  }

  .viewMore {
    color: #e60000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    cursor: pointer;
    border-bottom: 1px solid #e60000;
    font-weight: normal;
    margin-left: 10px;
  }

  .parent{
    display: flex;
    flex-wrap: wrap;   
  }
  .attachment {
    /* margin-left: 4px; */
    margin-right: 16px;
    border: solid 1px #979797;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: solid 1px #979797;
    padding: 5px 5px 5px 1px
  }

  .attachlogos{
    height: 36px;
    width: 36px;
    cursor: pointer;
  }

  .filename {
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    margin-top: 8px;
    font-size: 16px;
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 3px;
  }

  .attachlog {
    height: 26px;
    width: 26px;
    margin-top: 4px;
    cursor: pointer;
    margin-left: 10px;
  }

  .emptydiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #979797;
  }
.parents{
display: flex;
}
.parentst{
display: flex;
/* margin-top: 10px; */
}
  .attachmentCont{
    display: flex;
    /* flex-direction: row; */
    /* margin-top: 10px; */
  }

  .atachmentlabel{
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: auto 0;
  }
  .tooltipspan{
    font-weight: 700 !important;
    font-family: "VodafoneRg-Regular" !important;
    /* margin-left: 4px !important; */
    font-size: 16px !important;
    line-height: 28px !important;
    color: #333333 !important;
  }