.container{
     border-radius: 6px;
     border: 1px solid #979797;
     background-color: #FFFFFF;
     overflow-x: hidden;    
     padding: 10px;
     gap: 16px;
     margin-bottom: 20px;
     margin-top: 20px;
}
.searchhead{
    display: flex;
    justify-content: space-between;
}
.listContent{
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}
.list{
    flex: 1 1 20%;
    max-width: 20%;
}
.spacer {
    flex: 1 1 auto;
}
.head{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.subhead{
    display: flex;
    
}
/* .orgname{
    border-bottom: 1px solid #e60000;
    color: #e60000;
    cursor: pointer;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
} */
.status{
    border-radius: 6px;
    color: #333;
     /* background-color: #EB9700; */
    font-family: "VodafoneRg-Bold";
    font-size: 14px;
    /* margin-left: 10px;
    margin-top: 8px; */
    margin: auto 8px;
    padding: 0px 16px;
    height: 24px !important;
}
.pending{
    background-color: #FFE1AB;
    border: 2px solid #F4B035
  }
  .rejected{
    background-color: #FFAFAF;
    border: 2px solid #E64040
  }
  .approved{
    background-color: #C7FFE6;
    border: 2px solid #80D8B0
  }
.gotologo{
    height: 28px;
    width: 28px;
    /* margin-top: 5px; */
    cursor: pointer;
}
.label{
    color: #666666;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
}
.value{
    color: #000000;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 700;
}
.duedate{
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    background-color: #FFEECC;
    border-radius: 4px;
    margin-top: 20px;
    padding: 2px 5px;
}
.search{
    margin-top: -25px;
}

.Emptydiv{
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #979797;
    display: flex;
}

.newcomment{
  width: 26px;
  height: 26px;
  margin-left: 10px;
}
.notificationmain{
    position: relative;
    display: flex;
}
.notificationicon{
    margin-top: -14px;
    margin-left: -6px;
}
.indicator{
    display: flex;
}
.indicators{
    display: flex;
    justify-content: end;
  }
  .exclated{
  padding: 4px 12px 4px 12px;
  gap: 10px;
  border-radius: 4px;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 18.37px;
  text-align: left;
  background-color: #FFDADB;
  }

  .modifiedOn{
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    margin: auto 10px;
  }

  .organisationName{
    width: 100%;
    position: relative;
    display: flex;
    margin-left: 10px;
  }
  .orgname{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0px;
  }

  .orglgname > span {
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 0%;
    color: #E60000;
    cursor: pointer;
}
.posrelative{
  position: relative;
}
.link{
    text-decoration: none;
}
.customDataContent{
    display: flex;
    margin-left: 10px;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
    font-weight: 400;
  }

  .statusPending {
    background-color: #FFEA96;
    border: 1px solid #F4D55B;
  }
  .statusApproved {
    background-color: #ACEDD0;
    border: 1px solid #80D8B0;
  }
  .statusRejected{
    background-color: #FFAFAF;
    border: 1px solid #E60000;
  }
  .statusLegendsIcon {
    border-radius: 100px;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-bottom: -3.5px;
    margin-right: -6px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 5px;
  }
  .modifiedonwrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .highlightedRow{
    width: 3px;
    height: 57.5px;
    display: inline-block;
    position: absolute;
    line-height: -194px;
    left: -8px;
    top: -16.5px;
    background-color: #E60000;
    z-index: 99999;
  }
  .margingleft{
    margin-left: 10px;
  }
  
  .wrapperchild{
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 24px;
    margin: 0px -24px;
  }
  .boardingrequestlabel{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 24px 0;
  }
.sublabel{
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #333333;
  }
  .fincainfo{
    color: #333333;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    line-height: 18.37px;
    border-radius: 6px;
    background-color: #DCDCDC;
    margin: 0px -24px 24px -24px;
    gap: 5px;
    padding: 4px 8px;
  }
  
  .circleinfo{
    height: 20px;
    width: 20px;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 2px;
  }

  .infocircle{
    height: 22px;
    width: 22px;
    cursor: pointer;
    margin-bottom: 2px;
  }