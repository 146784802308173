.conatiner{
    display: flex;
    height: 56px;
    flex-direction: row;
    justify-content: center;
    background-color: #fff;
    margin-bottom: 24px;
}

.item{
    margin-right: 16px;
    font-family: "VodafoneLt-Regular";
    font-size: 20px;
    align-self: center;
    padding:16px 24px 16px 24px;
    cursor: pointer;
    position: relative;
 }
 .active{
    border-bottom: 3px solid #E60000;
    color: #E60000;
    position: relative;
 }

 .items{
    font-family: "VodafoneRg-Regular";
    position: relative;
    font-size: 16px;
    align-self: center;
    padding:4px 6px 4px 6px;
    cursor: pointer;
    border: 1px solid #666666;
    color: #666666;
    background-color: #ffffff;
    border-radius: 6px 6px 0px 0px;
 }

 .conatiners{
    display: flex;
    flex-direction: row;
    /* margin-bottom: 24px; */
}
.activetab{
    /* font-family: "VodafoneRg-Regular"; */
    background-color: #333333;
    color: #ffffff;
    font-size: 16px;
    align-self: center;
    padding:4px 6px 4px 6px;
    cursor: pointer;
    border: 1px solid #666666;
    font-family: "VodafoneRg-Bold";
    border-radius: 6px 6px 0px 0px;
 }
 .count {
   background-color: rgb(230, 0, 0);
   color: white;
   width: 24px;
   height: 24px;
   border-radius: 50%;
   font-size: 14px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   line-height: 24px;
   position: absolute;
   top: 20px;
   right: -5px;
   font-weight: 700;
 }