.tableContainer {
    border: 2px solid #CCCCCC; /* Outer border */
    border-radius: 6px; /* Border radius */
    /* overflow: hidden; Ensure border radius is applied */
  }
  
  .customTable {
    --bs-table-bg: initial !important; /* Override Bootstrap background color */
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
    border-color: #ddd !important;
  }
  
  .tableHeader {
    background-color: #CCCCCC !important; /* Header color */
    height: 45px;
  }
  
  .tableHeaderCell {
    padding: 0; /* Remove default padding */
    border-right: 2px solid #E6E6E6; /* Column separator border */
    text-align: center;
  }
  
  .tableHeaderCell:last-child {
    border-right: none; /* Remove border from the last header cell */
  }
  
  
  .headerContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    /* font-size: 16px; */
    /* font-weight: 700; */
    /* line-height: 18.34px; */
    text-align: left;
    font-family: "VodafoneRg-Regular";
    text-decoration-skip-ink: none;
  }
  
  .tableCell {
    border-right: 2px solid #E6E6E6;
    border-bottom: none;
  }

  .tableCell:last-child {
    border-right: none; /* Remove border from the last cell */
  }
  
  .cellcontent {
    /* display: inline-block; */
    width: 100%;
    display: flex;
    height: 40px;
    justify-content: flex-start;
    align-items: center;
  }

  .evenRow {
    background-color: #FFFFFF;
  }
  
  .oddRow {
    background-color: #F4F4F4;
  }

  .nodata{
    /* border-style: none !important; */
    margin-top: 10px;
  }

  .filterdata{
    text-align: center;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    border-style: none !important;
  }

  .tabledata tr:hover {
    outline: 1px solid #979797;
    box-shadow: 2px 2px 12px 0px #0000001A;
  }

  .selectedRow {
    background-color: #FFF0F0;
  }
  .selectable{
    cursor: pointer;
  }