.addButton{
  width: 221px;
  height: 48px;
  font-size: 20px;
  border-radius: 6px;
  color: #ffffff;
  border: 1px solid #999999;
  font-family: "VodafoneRg-Regular";
  background-color: #666666;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 88px - 40px);

  overflow: visible;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: stretch;
}

.expanded {
  grid-column: span 2 / span 2;
}

.footer {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 18.5px 16px;
  padding-bottom: 0px;
  margin-top: auto;
  /* position: fixed; */
  bottom: 0;
  right: 0;
  width: 100%;
  position: relative;
}


.footer::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #666666;
  left: 0;
  top: 0;
  transform: scaleX(1.8);
}
.inputError,
.selectError {
  border-color: #CD3500 !important
}


/* -------------------------------- */



.continue{
  border: 2px solid #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
  Width:186px;
  Height:48px;
  border-radius: 6px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  /* Padding:12px 16px 12px 16px; */
  cursor: pointer; 
  background-color: #E60000;
}
.stype{
color: #666666;
font-size: 20px;

font-family: "VodafoneRg-Regular";
align-items: center;
}
.buttonContainer {
  display: flex; 
}
.back{
  height: 48px;
  width: 148px;
}
.continuebutton{
  height: 48px;
  /* width: 148px; */
  background-color: #E60000;
  color: #FFFFFF;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  line-height: 28px;
  padding: 0 12px;
}
.continuebutton:disabled{
  /* background-color: gray; */
  opacity: 0.5;
  cursor: not-allowed;
}
.saveButton{

}
.separator {
margin-block-start: 0em;
margin-block-end: 0em;
margin-top: 10px;
border-color: #666;
transform: scaleX(1.5);
opacity: 100;
}
.separatortop {
margin-top: 25px;
border-color: #666666;

opacity: 100;
}
.submit{
  background-color: #E60000;
  color: #FFFFFF;
  border: none;
  Width:148px;
  Height:48px;
  border-radius: 6px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  /* Padding:12px 16px 12px 16px; */
  cursor: pointer; 
}
.Buttons{
margin-top: 400px;
}
.radioLabel {
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  padding-left: 5px;  
  margin-top: 0px;                   
}
.headsearch{
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e6e6e6e6;
}
.orgnamehead{
  font-size: 24px;
  font-family: "VodafoneRg-Regular";
  color: #333333;
}
.containerlist{
  /* overflow-x: hidden; 
  overflow-y: auto;
  max-height: 450px; */
}
.radioInput {
  width: 20px;
  height: 20px;
  accent-color: #00697c;
margin-top: 3px;
}

.radioField {
  display: flex;
  
  flex-direction: row;


}
.radiostyle{
  margin-right: 240px;
}
.radiodiv{
  display: flex;
margin-top: 5px;
margin-right: 50px;
}
.radiodivs{
  display: flex;
margin-top: 5px;
margin-right: 160px;
}
.wrapperContainer{
  display: flex;
  align-items: center;
  flex: 1;
}
.circle{
margin-left: 20px;
margin-top: 1px;
}
.circlered{
  /* margin-left: 5px; */
margin-bottom: 3px;
    }
    .circleimg{
      margin-bottom: 3px;
    }
.note{
color: #666666;
font-size: 20px;
font-family: "VodafoneRg-Regular";
margin-bottom: 10px;
}
/* ivc */
.buttonContainer {
  display: flex;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  color: #666666; 
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-style: normal; 
}
.backButton {
  background-color: #666666;
}
.labelcomment{
  margin-bottom: 6px;
}
.separator {
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: 1px solid #666666;
  width: 100%;
  margin-left: -24px;
  opacity: 1 !important;
}
.additionalStyle{
  margin-top: 2px !important;
}
.commentValidation {
  color:#666666;
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  text-align: right;
}
.onboardinfoimg{
  height: 361px;
  width: 100%;
}
.servicetype{
  display: flex;
  flex-direction: row;
}
.textarea{
margin-top: 25px;
}

/* IPX */
.dropdowngrid{
display: flex;
justify-content: space-between;
margin-top: 25px;
}
.dropdownwidths{
padding-right: 48px;
}
.dropdownwidth{
flex: 1;

}
.maintextbox{
display: flex;
}
.textbox{
flex: 1;
margin-bottom: 25px;
margin-top: 5px;
margin-right: 24px;
}
.continueboth{
display: flex;
/* border: 2px solid #FFFFFF; */
border: none;
background-color: #E60000;
color: #FFFFFF;
Width:223px;
Height:48px;
border-radius: 6px;
font-size: 20px;
font-family: "VodafoneRg-Regular";
Padding:12px 16px 12px 16px;
justify-content: center;
align-items: center;
cursor: pointer; 
}
.sideNavStyle{
width: 60% !important;
overflow: hidden;
}
.obj{
width: 100%;
}
.FailuerTypeDropdownLabel{
font-family: "VodafoneRg-Regular";
font-size: 20px;
color: #666666;
font-weight: 400;
flex: 1;
}
.FailuerTypeDropdown{
flex: 2;
}
.form{
display: flex;
flex-direction: column;
min-height: calc(100vh - 88px - 180px);
}
/* footer */
.footer {
align-items: end;
justify-content: end;
}
/* component level  */
.labelcompLevel{
font-size: 18px;
font-family: "VodafoneRg-Regular";
color: #333333;
}
.additionalStyle{
font-size: 18px;
}
.scroll{
overflow-x: hidden;
max-height: 50px;
overflow-y: auto;
}


.fieldWrapper {
  display: flex;
  flex-direction: column;
  color: #666666; 
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  font-style: normal; 
}

/* footer */


.form{
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 96px - 48px - 48px);
}

.footer {
  align-items: end;
  justify-content:space-between;
}

.separator {
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: 1px solid #666666;
  width: 100%;
  margin-left: -24px;
  margin-right: 0%;
  opacity: 1 !important;
  transform: scaleX(1.5);
}

/* list */

.containers {
  overflow-x: hidden;
  border-radius: 6px;
  border: 1px solid #999999; 
  padding: 10px;   /*padding: 20px; */
  gap: 16px; 
  background-color: white;
  margin-bottom: 20px;
}
.wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: normal;
  }
  .parentDiv{
      display: flex;
      flex:4;
      margin-bottom: 20px;
  }
  .headData{
      display: flex;
      flex-direction: column;
      flex:1;
      }
      .sub{
          color: #000000;
          font-size: 18px;
          font-family: "VodafoneRg-Regular";
          font-weight: 700;
      }
      .headSub{
          color: #666666;
          font-size: 18px;
          font-family: "VodafoneRg-Regular";
      }
      .customerNotFound{
          color: #151515;
          font-size: 18px;
          font-family: "VodafoneRg-Regular";
          background-color: #f3f3f3;
          height: 409px;
         align-content: center;
          justify-content: center;
          text-align: center;
          border-radius: 8px;
      }
      .containerheader{
        font-family: "VodafoneRg-Regular";
        color: #666666;
        font-size: 20px;
        margin-bottom: 20px;
        background-color: #FFEBCD;
        padding-left: 10px;
      }
      .headnote{
        font-family: "VodafoneRg-Regular";
        font-size: 14px;
        color: #333333;
        background-color: #FFEBCD;
        border-radius: 6px;
        height: 32px;
        display: flex; 
        align-items: center;
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .infocircle{
        height: 20px;
        width: 20px;
        margin: 0px 5px 0px 10px;
    }
      .subhead{
        cursor: pointer;
      }
      .customerNotFoundtxt{
          color: #666666;
          font-size: 18px;
          font-family: "VodafoneRg-Regular";
 
      }
      .customerNottxt{
        color: #333333;
        font-size: 28px;
        font-family: "VodafoneRg-Regular";

    }
.chackboxmain{
  display: flex;
  gap: 150px;
}
.select{
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.dropdowngrid{
 
}
.dropdownwidths{
  padding-right: 48px;
}
.selectwidth{
  flex: 1;
  
}
.chackboxtxt{
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  color: #666666;
  margin-top: 5px;

}
.ipxform{
  background-color: #00697C;
  color: #FFFFFF;
  border-radius: 4px;
  padding: 6px 8px;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 700;
}
.initalinfo{
  height: 361.42px;
  background-color: #f3f3f3;
  border-radius: 6px;
  border: 1px solid #000000;
}
.initalinfohead{
  color: #333333;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  margin: 30px 0px 30px 20px;
}
.initalinfotext{
  color: #333333;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
   height: 105px;
  background-color: #FFFFFF;
  border-radius: 6px;
  margin: 5px 20px 20px 0px;

  padding: 10px;
  padding-left: 60px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.info{
  color: #333333;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
}

.label{
  color: #333333;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
}
.containerinfo{
  display: flex;
}
.dflexs{
  margin: 45px 26px 0px 0px;

}
.dflex{
  
  flex: 1;
}
.redflex{
  display: flex;
  flex-direction: row;
 margin: 0px 0px 0px 20px;
}
.redin{
  background-color: #E60000;
  padding: 5px;
  height: 1px;
  width: 1px;
  
  border-radius: 100%;
}
.blackin{
  background-color: #424856;
  padding: 4px;
  margin: 2px 5px;
  border-radius: 100%;
}
.red{
  background-color: #E60000;
  padding: 5px;
  margin: 10px 20px;
  border-radius: 100%;
}
.black{
  background-color: #424856;
  padding: 4px;
  margin: 10px 20px;
  border-radius: 100%;
}
.userinfosearchimg{
   height: 59px;
  width: 59px;
}
.userinfosearch{
  position: absolute;
  top: 22px;
  left: -30px;

}

.serchicon{
  height: 24px;
  width: 24px;
  right: 45px;
  margin: auto 5px;
  /* right: 45px;
  margin-top: 45px;
  position: absolute; */
}

.amnote{
  display: block;
  font-family: "VodafoneRg-Regular";
  /* margin: 0px 24px 0px 24px; */
  padding: 7px 8px 7px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #333333; 
  border-radius: 6px;
  background-color: #ffebcd;
}
.navigatevf{
  color: #e60000;
  /* margin-left: 10px; */
  text-decoration: underline;
  cursor: pointer;
}
.parent{
  border: 1px solid #979797;
  border-radius: 6px;
  display: flex;
}
.parent > div{
  width: 100%;
}

.parent > div > input{
  border: none !important;
}

.textLimitInfo{
  font-family: "VodafoneRg-Regular";
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: end;
  justify-content: end;
  height: 0px;
  position: relative;
  top: 20px;
}

.fincadetail{
  position: relative;
  float: inline-end;
  cursor: pointer;
  height: 0px;
  font-size: 18px;
  font-family: "VodafoneRg-Regular";
  color: #333333;
}


/* --------------Finca Popup ----------------------- */
.alertmessage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.alertcontent {
  background: white;
  /* padding: 15px; */
  border-radius: 5px;
  text-align: center;
  width:  599px
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 10px 20px;
}
.headertext{
  font-family: 'VodafoneRg-Regular';
  font-weight: 700;
  font-size: 24px;
  line-height: 27.5px;
  letter-spacing: 0px;
  color: #333333;
}
/* .closepopup{
  cursor: pointer;
} */

.close{
  width: 16px;
  height: 16px;
}

.infomsg{
  margin: 0 20px;
  background-color: #FFEBCD;
  padding: 5px;
  text-align: left;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.07px;
  letter-spacing: 0px;
  font-family: 'VodafoneRg-Regular';
  color: #333333;
}

.tableparent{
  /* max-height: 200px; */
  margin: 20px;
  /* overflow-y: auto; */
  /* border: 2px solid #CCCCCC; */
  /* border-radius: 6px;  */
  /* overflow: hidden;  */
}

.customfincatable{
  --bs-table-bg: initial !important;
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: #ddd !important;
}
.customtable{
  --bs-table-bg: initial !important;
}

.tableheader{
  background-color: #CCCCCC !important;
  border-radius: 6px !important;
}

.evenRow {
  background-color: #FFFFFF;
}
.oddRow {
  background-color: #F4F4F4;
}

.fincaHeader{
  font-family: 'VodafoneRg-Regular';
  font-weight: 700;
  font-size: 18px;
  color: #333333 !important;
  line-height: 20.63px;
  letter-spacing: 0px;
}

.fincaVal{
  font-family: 'VodafoneRg-Regular';
  font-weight: 400;
  font-size: 16px;
  color: #333333 !important;
}

.inputfieldcontainer{
    margin: 10px 20px;
    display: flex;
    align-items: center;
    border: 1px solid #666666;
    border-radius: 6px;
    overflow: hidden;
}
.searchInput{
  width: 92%;
  /* border-radius: 6px 0px 0px 6px; */
  padding: 8px;
  border: none;
}

.inputfieldcontainer > input:focus-visible{
  outline: none;
}

.searchwrapper{
  width: 9%;
  background-color: #666666;
  padding: 6px;
  border-radius: 0px 4px 4px 0px;
}

.serchimage{
  width: 28px;
}

.footerfinca{
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.addcode{
  border-radius: 6px;
  font-family: 'VodafoneRg-Regular';
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  font-weight: 400;
  border: navajowhite;
  color: #FFFFFF;
  border: none;
  background-color: #E60000;
  padding: 12px 81px;
  margin: 10px;
}

.disablebgtn{
  opacity: 0.5;
}

.backButton{
    display: flex;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 6px;
    height: 48px;
    border: 1px solid #999999;
    width: 148px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 12px 16px 12px 16px;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    cursor: pointer;
    margin: auto auto auto 0px;
}

.fincadesc{
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 16.07px;
  letter-spacing: 0px;
  color: #333333;
  border-radius:6px;
  background-color: #FFEBCD;
  padding: 7px 8px 7px 8px;
  position: relative;
  margin-bottom: -30px;
}
.icon{
  position: relative;
}

.iconparent{
  position: absolute;
    right: 6px;
    bottom: 10px;
    background-color: #EBEBEB;
    border-radius: 6px;
}
.questionicon{
  height: 20px;
  width: 20px;
  padding: 3px;
  margin: 4px;
}

.statusLegendsIcon {
  border-radius: 100px;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-bottom: -1px;
  margin-right: 5px;
  background-color: #F4B035;
}

.cnfcontent{
  background: white;
  border-radius: 5px;
  text-align: center;
  width:  500px;
  padding: 10px;
}

.confirmheader{
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 24px;
  line-height: 27.5px;
  letter-spacing: 0px;
  color: #333333;
}

.infomsgs{
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 18px;
  line-height: 20.66px;
  letter-spacing: 0px;
  color: #333333;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px 0px
}

.cnfmsg{
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 18px;
  line-height: 20.63px;
  letter-spacing: 0px;
  color: #333333;
}

/* .cnfparent{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-radius: 6px;
  border: 0.5px solid #999999;
  margin-top: 20px;
  overflow: hidden;
} */

.ipxcont{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.ipxcont::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 35%;
  border-right: 0.5px solid #999999;
}

.cfparent{
  align-items: center;
  border-radius: 6px;
  border: 0.5px solid #999999;
  margin-top: 20px;
  overflow: hidden;
}

.ipxcontlabel{
  margin: auto;
  width: 35%;
  padding: 14px;
  font-family: "VodafoneRg-Regular"
}

.ipxcontval{
  margin: auto;
  width: 65%;
  word-break: break-word;
  padding: 14px;
  font-family: "VodafoneRg-Regular"
}

.fincaco{
  border-right: 0.5px solid #999999;
  width: 100%;
  padding: 14px;
  text-align: center;
}

.fincaval{
  width: 100%;
  padding: 14px;
  text-align: center;
  height: -webkit-fill-available;
}

.bkcolor{
  background-color: #F4F4F4;
  /* border-radius: 0px 0px 6px 6px; */
}

.confirmfooter{
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.cancelButton{
  border-radius: 6px;
  gap: 10px;
  padding: 6px 40px;
  border-width: 1px solid #999999;
  background-color: #FFFFFF;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #000000;
  border: 1px solid #999999;
}

.okButton{
  border-radius: 6px;
  gap: 10px;
  padding: 6px 50px;
  border-width: 1px solid #999999;
  background-color: #E60000;
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  border: none;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #FFFFFF;
}

.cnfloader{
  display: flex;
  justify-content: flex-end;
  height: 0px;
}

.borderbtm{
  border-bottom: 0.5px solid #999999;
}