.fieldContainer {
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  flex-grow: 1;
  font-weight: 400;
  line-height: 28px;
  text-decoration-skip-ink: none;
}

.fieldContainer > div > div >div > div > div{
  font-size: 16px;
}

/* .inputError,
.selectError {
  border-color: #cd3500 !important;
} */

.label {
  /* display: flex; */
  font-family: "VodafoneRg-Regular";
  margin-bottom: 10px;
  color: #100045;
}
.radioLabel {
  margin-left: 8px;
}


/* ------------------------- */

/* .fieldWrapper {
  margin-bottom: 16px;
} */

.labelWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.label {
  font-weight: bold;
}

.asterisk {
  /* color: red; */
  margin-left: 4px;
}

.textInput {
  width: 100%;
  padding: 8px;
  height: 48px;
  border: 1px solid #979797;
  border-radius: 4px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-decoration-skip-ink: none;
}

.inputError {
  /* border-color: red; */
}

.errorLabel {
  /* color: red; */
  font-size: 12px;
  margin-top: 4px;
}

.focusfield{
  background-color: #007c92;
  color: #FFFFFF;
}

/* .wrapper > div > div{
  background-color: #007c92;
}

.wrapper > div > div > div > div::before{
  background-color: #007c92;
}

.wrapper > div > div > div > div{
  color: #FFFFFF;
} */

.arrowdown{
  position: absolute;
  right: 3px;
  bottom: 12px;
}

.selectedarrow{
  position: absolute;
    right: 7px;
    bottom: 19px;
    height: 11px;
    width: 18px;
}
.arrowup{
  transform: rotate(180deg);
}