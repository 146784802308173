.statusContainer{
    display: flex;
    padding: 10px;
    gap: 8px;
    border-radius: 6px;
    background-color: #FFFFFF;
    border: 1px solid #CBCBCB;
    margin-bottom: 20px;
}

.labeltext{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #333333;
}

.radioField{
    margin: auto 0px auto 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.radioInput {
    width: 20px;
    height: 20px;
    margin: 0px 6px 0px 0px;
    position: relative;
    cursor: pointer;
  }

.radioLabel{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    font-weight: 400;
    line-height: 18.37px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;
}

.infoImage {
    margin-left: 8px;
    margin-bottom: 4px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }