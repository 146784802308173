
.overlayInnerbox {
    position: absolute;
    right: 80px;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin-top: 12px;
    width: 518px;
    height: 554px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.reminderInnerbox {
    position: absolute;
    right: 0px;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin-top: 1px;
    width: 161px;
    height: 76px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
    padding: 6px;
    cursor: pointer;
}
.mainreminder{
    position: relative;
}
.reminderoption{
    color: #333333;
    font-size: 14px;
    font-family: "VodafoneRg-Regular";
    background-color: #f5f5f5;
    margin-bottom: 4px;
    border-radius: 6px;
    padding: 4px ;
    transition: background-color 0.3s;
}
.reminderoption:hover {
    background-color: #e2e2e2; /* Background color when hovered */
}
.headerContainer{
    display: flex;
    border-bottom: 1px solid #CCCCCC;
    height: 68px;
    align-items: center;
    background-color: #F9F9F9;
    border-radius: 6px 6px 0px 0px; 
}
.notificationmessage{
    font-family: "VodafoneLt-Regular";
    font-size: 16px;
    color: #333333;
}
.notificationmessageread{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    color: #333333;
}

.header {
    flex: 1;
    font-family: "VodafoneRg-Bold";
    font-size: 16px;
    height: 48px;
    line-height: 48px;
}
.close{
    align-content: flex-end;
    align-self: center;
    cursor: pointer;
}

.applybtn {
    cursor: pointer;
    border: none;
    background-color: #FFFFFF; 
}
.count{
    font-family: "VodafoneRg-Regular";
    font-size: 11px;
    color: #ffffff;
    background-color: #E60000;
    border-radius: 50%;
    z-index: 1;
    height: 17px;
    width: 17px;
    display: flex;
    justify-content:center;
    align-items:center;
    position: absolute;
    top: 2px;
    right: -2px;
}

.userIcon{
    display: flex;
    position: relative;
    cursor: pointer;
}
.icon{
    display: flex;
    position: relative;
}
.notificationheader{
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    color: #E60000;
    border-bottom:2px solid #E60000;
    display: inline-block;
}
.notificationicon{
    height: 36px;
    width: 36px;
}
.value{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    color: #333333;
}
.closeimg{
    height: 26px;
    width: 26px;
}
.block{
    padding: 10px;
    border: 1px solid #CCCCCC;
    /* cursor: pointer; */
    /* height: 122px; */
    padding: 12px 0px;
}
.notificationContainer{
    height: 476px;
    overflow: auto;
}
.block:hover{
    background-color: #bf1919;
}
.new{
    background-color: #E60000;
    height: 8px;
    width: 8px;
    border-radius: 50%; 
    margin: 8px 6px 0px 6px; 
}
.mainblock{
    display: flex;
}
.message{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.messagemain{
    display: flex;
    flex-direction: column;

}
.blocktop{
    display: flex;
    justify-content: space-between;
}
.blockinner{
    width: 100%;
    padding-right: 10px;
}
.newmessage{
    width: 20px;
}
.modulename{
    display: flex;
    justify-content: space-between;
    /* padding-top: 10px; */
}
.module{
    font-size: 14px;
    color: #666666;
    font-family: "VodafoneRg-Regular";
}
.dropdown{
    margin-left: 15px;
}
.id{
    color: #E60000;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
    cursor: pointer;
    border-bottom: 1px solid #E60000;
}
.idread{
    color: #E60000;
    font-weight: 700;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
    cursor: pointer;
    border-bottom: 1px solid #E60000;
}
.status{
    color: #333333;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
}
.statusread{
    color: #333333;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
}
.clockIcon{
    height: 20px;
    width: 20px;
}

  .notificationContainer::-webkit-scrollbar {
    width: 8px; 
  }
  .notificationContainer::-webkit-scrollbar-thumb {
    background-color: #C2C2C2; 
    border-radius: 10px; 
  } 
  /* .notificationContainer::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  } */
  .notificationContainer::-webkit-scrollbar-track {
    background: transparent;
    border: none !important;
  }
  .Emptydiv{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #979797;
    border-radius: 6px;
    padding: 20px;
    margin: 10px;
    font-family: "VodafoneRg-Regular";
  }