.modal {
    position: absolute;
    margin-top: 9px;
    right: 5px;
    /* transform: translate(-50%, -50%); */
    width: 400px;
    height: 412px;
    background-color: #FFFFFF;
    border-radius: 6px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    /* padding: 12px; */
    border: 1px solid #979797;
    z-index: 1000;
    font-weight: 400;
}

/* .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
} */

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid #979797;
    padding: 12px;
}

.header > div {
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
}

.close{
    height: 16px;
    width: 16px;
}

.multiselect{
    padding: 12px;
    border-bottom: 1px solid #979797;
    height: calc(400px - 100px);
}

.label{
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0px;
    color: #333333;
    margin-bottom: 8px;
}

.footer{
    padding: 12px;
    display: flex;
    justify-content: space-between;
}

.applyButton, .closeButton {
    height: 40;
    padding: 8px 50px;
    gap: 10px;
    border-radius: 6px;
    border-width: 1px;
}

.applyButton {
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    font-size: 14px;
    /* line-height: 16.04px; */
    letter-spacing: 0px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    background-color: #E60000;
}

.closeButton {
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    font-size: 14px;
    /* line-height: 16.04px; */
    letter-spacing: 0px;
    text-align: center;
    color: #333333;
    background-color: #FFFFFF;
}

.disabled{
    opacity: 0.5;
}

/* ------------------------------------------------------------------------- */

.parent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 6px;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    border-radius: 6px;
    background-color: #f4f4f4;
    width: calc(100vw - 290px);
    margin: 0 6px;
    cursor: pointer;
}
.subparent{
    display: flex;
    justify-content: start;
    align-items: center;
}

.text{
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 18.37px;
    letter-spacing: 0px;
    color: #333333;
    margin-right: 6px;
}

.invoiced{
    display: flex;
    border: 1px solid #979797;
    background-color: #ffffff;
    padding-top: 6px;
    padding-right: 8px;
    padding-bottom: 6px;
    padding-left: 8px;
    gap: 12px;
    border-radius: 6px;
    border-width: 1px;
    margin-left: 10px;
    align-items: center;
}

.invoicedtext{
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #333333;
}
.invoicedData {
    display: flex;
    /* max-width: 350px; */
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.wrapper::-webkit-scrollbar {
    width: 0;
    height: 0; 
}

.wrapper::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.wrapper::-webkit-scrollbar-track {
    background-color: transparent;
}

.invoicelabel{
    padding: 2px 6px;
    gap: 8px;
    border-radius: 6px;
    width: 246;
    color: #333333;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    background-color: #FFF0F0;
    /* background-color: #FFFFFF; */
    margin-right: 5px;
}

.cancel{
    width: 14px;
    height: 14px;
    margin-left: 10px;
    margin-bottom: 3px;
}

.resetfil{
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
    color: #E60000;
    cursor: pointer;
    margin-left: 6px;
}

.reseticon{
    width: 24px;
    height: 24px;
}