.confirmationpopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #33333399;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2 !important;
  opacity: 1;
}
  .confirmationpopupcontent {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    width: 439px;
    opacity: 1 !important;
    text-align: left; 
  }
.buttons{
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
  .submit{
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    border-radius: 6px;
    background-color: #E60000;
    color: #FFFFFF;
    padding: 12px 16px;
    border: none;
    width: 178px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cancle{
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    border-radius: 6px;
    padding: 12px 16px;
    background-color: #FFFFFF;
    border: 1px solid #999999;
    width: 178px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .close{
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
  .headertxt{
    font-family: "VodafoneRg-Regular";
    font-size: 20px;
    font-weight: 700;
    color: #333333;
  }