.collapsibleContainer {
    border: 1px solid #979797;
    border-radius: 6px;
    padding: 10px;
    background-color: #FFFFFF;
    margin-bottom: 20px;
  }
  
  .collapsible {
    background-color: #FFFFFF;
    border-radius: 6px;
    color: rgb(0, 0, 0);
  }
  .notify{
    color: #666666;
    font-size: 14px;
    font-family: "VodafoneRg-Regular";
  }
  .titleContainer {
    display: flex;
    padding-top: 10px;
    margin-top: 5px;
    border-top: 2px solid #E6E6E6;
  }

  .issue{
    color: #000000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
  }
  .summarydata{
    color: #333333;
    font-size: 18px;
    font-family: "VodafoneRg-Regular";
  }
  .summary{
    margin-top: 14px;
    padding-bottom: 5px;
  }
  .SRid{
    border-bottom: 1px solid #E60000;
    color: #E60000;
    cursor: pointer;
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 4px;
    font-family: "VodafoneRg-Regular";
  }
  .comment{
    height: 19px;
    width: 19px;
    cursor: pointer;
  }
  .contentInner {
    display: flex;
  }
  .DetailsDiv {
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    min-width: 20%;
    max-width: 20%;
    /* flex: 1; */
  }
  .onhoverdiv{
    display: flex;
    
  }
  .border{
    border-right: 1px solid #E6E6E6;
    padding: 0px 40px 0px 0px;
  }
  .leftpadd{
    padding: 0px 0px 0px 20px;
  }
  .data{
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    font-family: "VodafoneRg-Regular";
  }
  .header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
.status{
    color: #333;
    font-family: "VodafoneRg-Bold";
    /* background-color: #EB9700; */
    font-size: 14px;
    border-radius: 6px;
    padding: 5px 16px;
    height: 26px;
    margin-left: 10px;
}
/* tooltip */
.tooltip {
  position: relative;
 margin-right: 10px;
}
.infoCircle{
  height: 19px;
  width: 19px;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #F4F4F4;
  color: #000000;
  text-align: center;
  border-radius: 6px;
  /* border: 1px solid #CCCCCC; */
  padding: 3px 0;
  position: absolute;
  z-index: 1;
  right:30px;
  top:-12%;
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "VodafoneRg-Regular";
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top:11px;
 right : -9px;
  border-width: 5px;
  border-style: solid;
  border-color:  transparent transparent transparent #F4F4F4;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.notificationmain{
  position: relative;
  margin-left: 8px;

}
.notification{
  position: absolute;
  top: 2px;
  left: 19px;
  background-color: #BD0000;
  color: #BD0000;
  font-size: 1px;
  padding: 5px;
  border-radius: 100%;
}

 /* status background color */
.statusLegendsIcon {
  border-radius: 100px;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-bottom: -3.5px;
  margin-right: -6px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 5px;
}
.new{
  background-color: #FFF1BB;
  border: 2px solid #F4D55B
}
.assigned{
  background-color: #00B0CA;
}
.pending{
  background-color: #BD0000;
}
.in_progress{
  background-color: #FFE1AB;
  border: 2px solid #F4B035
}
.resolved{
  background-color: #C7FFE6;
  border: 2px solid #80D8B0
}
.closed{
  background-color: #E8E8E8;
  border: 2px solid #C2C2C2
}
.need_more_information{
  background-color: #D5F9FF;
  border: 2px solid #89DBE9
}
.customer_feedback_awaited{
  background-color: #EEECFF;
  border: 2px solid #9188E0
}

.warning{
  /* position: relative;
  float: right;
  top: -25px; */
  display: flex;
  flex-direction: row-reverse;
}

.warningtext{
  padding: 4px 12px;
  gap: 10px;
  border-radius: 4px;
  background-color: #FFEECC;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.37px;
  text-align: left;
  font-family: "VodafoneRg-Regular";
  color: #000000;
  display: flex;
  align-items: center;
}

/* .indicator{
  font-size: 16px;
  font-weight: 400;
  line-height: 18.37px;
  text-align: left;
  font-family: "VodafoneRg-Regular";
  color: #666666;
  gap: 4px;
} */

.indicator > span{
  height: 12px;
  gap: 0px;
  width: 12px;
  background: #BD0000;
}
.commentnotification{
  position: absolute;
  top: 3px;
  right: -2px;
  background-color: #BD0000;
  color: #BD0000;
  font-size: 1px;
  padding: 5px;
  border-radius: 100%;
}

@media (max-width: 1100px) {
  .warning{
    position: inherit;
    float: none;
    display: flex;
    flex-direction: row-reverse;
  }
}

.newcomment{
  height: 26px;
  width: 26px;
}

.Emptydiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  background-color: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #979797;
}
.parentdiv{
  display: flex;
  /* margin-right: auto 10px; */
}

.submitedOn{
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
  margin: auto 10px;
}
.issuetitle{
  color: #666666;
  font-size: 16px;
  font-family: "VodafoneRg-Regular";
}

.wrapperchild{
  background-color: #FFFFFF;
  border-radius: 6px;
  padding: 24px;
  /* margin: 0px -24px; */
}

.organisationName{
  width: 100%;
  position: relative;
  display: flex;
  margin-left: 10px;
}
.orgname{
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0px;
}

.orglgname > span {
  font-family: "VodafoneRg-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 0%;
  color: #E60000;
  cursor: pointer;
}
.posrelative{
position: relative;
}
.link{
  text-decoration: none;
}
.customDataContent{
  display: flex;
  margin-left: 10px;
  font-family: "VodafoneRg-Regular";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2%;
  font-weight: 400;
}

.modifiedonwrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.incidentlabel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 24px 0;
}
.sublabel{
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #333333;
}

.parentnoti{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.infocircle{
  margin-bottom: 2px;
}

.sorting{
  position: absolute;
  right: 0;
  bottom: -4px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.asc{
  transform: rotate(180deg);
}