/*CSS*/

.listContainer {
    /* display: flex; */
    flex: 1;
    align-self: stretch;    
    margin-top: 30px;
}
.servicesList {
    display: flex;
    flex: 1;
    flex-direction: column;
    list-style: none;
    justify-content: center;
    padding-left:0px;
    margin-bottom: 0px;
}
.servicesList > li {
    cursor: pointer;
    margin-bottom: 24px;
}
.commentBox img{
    width: 36px;
     height: 36px;
     cursor: pointer;

}
.hideList {display: none;}
.header{
     /* border-bottom: 1px solid #E60000; */
     display: flex;
      }
.headeright{
    display: flex;
    /* gap: 70px; */
}
.commentIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-left:10px;
}
.ammendServiceIcon {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-left:10px;
}
/* searchbar */

.mainContainer {
   /* display: flex;
    align-items: end;
    vertical-align: baseline;*/
    display: flex;
    justify-content:space-between;
    align-items: end;
}
.searchContainermain{
  display: flex;
  align-items: center;
}
.labelor {
    margin: 35px 20px 10px 20px;
    font-family: "VodafoneRg-Regular";
    font-size: 18px;
}
.labelstatus {
    margin-bottom: 2px;
    font-family: "VodafoneRg-Regular";
    color: #666666;
    font-size: 14px;
}
.inputContainer{
    width: 305px;
}
.listCardMmargin{
    /* cursor: pointer; */
    margin-bottom: 30px;
}
.searchContainer { 
    /*flex: 8;*/
}
.filterContainer {
   /* flex: 1;*/
}

.formikBtnContainer {
    display: flex;
    justify-content: space-between;
   /* padding: 5px; */
    gap:10px;
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
    font-weight: 700;
}

.formikBtnClear{
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    flex: 1;
    height: 40px;
    border : solid 1px #999999;
    background-color: #FFFFFF;
}

.formikBtnSubmit {
    color: #fff;
    background-color: rgb(32, 19, 19);
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    flex: 1;
    height: 40px;
}
.dropdownWidth {
    width: auto !important;
}
.selectedFilters{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.selectedItem{
    background: #EBEBEB;
    border-radius: 26px;
    border: 1px solid #979797;
    margin-right: 16px;
    height: 36px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 8px 12px 8px 12px;
    margin-bottom: 16px;
}
.label{
    font-family: "VodafoneRg-Bold";
    font-size: 14px;
    margin-right: 8px;
}
.value{
    font-family: "VodafoneRg-Regular";
    font-size: 14px;
}
.selectedFilterContainer {
    display: flex;
    margin-top: 20px;
}
.fieldContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  
  .inputError,
  .selectError {
    border-color: #CD3500 !important
  }  

  .applyFilterBtn {
    height: 48px !important;
    margin-top: 2px;
    width: 127px;
  
  }
  .labelShowFilter {
    color: #000000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    margin-top:4px;
  }
  
.download {
    cursor: pointer;
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #999999;
    border-radius: 6px;
    font-size: 14px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    height: 48px !important;
    width: 52px;
    margin-top: 25px;
    margin-left: 10px;
  }
.orgnamelable{
  font-size: 20px;
  font-family: "VodafoneRg-Regular";
  font-weight: 700;
  color: #333333;
   margin-bottom: 10px;
}
  
.filterContainer {
    display: flex;
    justify-content: end;
    flex: 1;
    margin-right: 10px;
}
.downloadlogo{
    /* margin-left: 10px;
    margin-bottom: 5px; */
    height: 36px;
    width: 36px;
}

.videomain{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.video{
    width: 100%;
    height: 250px;
}
.moduledescription{
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    color: #333333;
    background-color: #DCDCDC;
    /* height: 36px; */
    padding: 8px 8px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 20px;
  }

  .marginfromtop{
    margin-top: 24px;
  }
  
  .descriptioncircle{
    padding: 0px 5px;
  }

  
  .sorting{
    position: absolute;
    right: 0;
    bottom: -4px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  
.invoiceStatusLegends {
    display: flex;
  }
  .statusLegendsLabel {
    margin-right: 20px;
  }
  .statusLegendsIcon {
    border-radius: 100px;
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-bottom: -4px;
    margin-right: 5px;
    /* border: 1px solid #ACEDD0; */
  }
  .statusLegendsIconAmber {
    background-color: #FFEA96;
    border: 1px solid #F4D55B;
  }
  .statusLegendsIconGreen {
    /* background-color: #009900; */
    background-color: #ACEDD0;
    border: 1px solid #80D8B0;
  }
  
  .statusLegendsIconBlue {
    background-color: #E2DFFF;
    border: 1px solid #9188E0;
  }
  .statusLegendsIconcompleted{
    background-color: #C7FFE6;
    border: 1px solid #80D8B0;
  }
  .statusLegendsIconinprogress{
    background-color: #FFE1AB;
    border: 1px solid #F4B035;
  }
  .statusLegendsIconclose{
    background-color: #E8E8E8;
    border: 1px solid #C2C2C2;
  }
  .statusLegendsIconhold{
    background-color: #D4D4D4;
    border: 1px solid #9B9191;
  }
  .spanSpaceing {
    /* margin: 6px 10px 1px 10px; */
  }
  .customDataContent{
    display: flex;
    margin-left: 10px;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
    /* margin: auto; */
  }
  
  .customDataContents{
    display: flex;
    margin-left: 10px;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
    cursor: pointer;
  }
  
  .invoiceId {
    color: #e60000;
    text-decoration: underline;
    cursor: pointer;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
  }
  
  .invoiceIds{
    color: #e60000;
    text-decoration: underline;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
  }
  
  .organame {
    /* cursor: pointer; */
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
  }
  
  .texttruncate{
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .tooltip > span {
    white-space: nowrap;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .emptydiv{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100px; */
    font-size: 20px;
    font-family: "VodafoneRg-Regular";
    background-color: #F4F4F4;
    border-radius: 8px;
    /* border: 1px solid #979797; */
    padding: 16px 24px;
    /* margin-bottom: 10px; */
    height: calc(100vh - 120px - 132px - 132px - 51px);
  }
  
  .filter{
    position: absolute;
    right: 0;
    bottom: -4px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  
  .invoicedue{
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .downloadlogo{
    /* margin: auto 10px 7px 0px; */
    cursor: pointer;
  }
  
  .disabledownloadlogo{
    margin: auto 10px 7px 0px;
    cursor: none;
    opacity: 0.5;
  }
  
  .orgname{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-family:"VodafoneRg-Regular";
    /* font-size: 16px; */
    font-weight: 700;
  }
  
  .monthparent{
    width: 210px;
  }
  
  .invoiceStatus {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 20px;
    border-radius: 6px;
  }
  
  .statuslab{
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    font-size: 16px;
    line-height: 18.34px;
    color: #333333;
    margin-left: 10px;
  }
  
  .statusval{
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 18.37px;
    color: #333333;
    margin-left: 5px;
  }
  
  .statusLegend {
    margin-bottom: 5px;
  }
  
  .organisationName{
    width: 100%;
    position: relative;
  }
  
  .documentDate{
    width: 100%;
    position: relative;
  }
  
  .document{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 10px;
  }
  .wrapper{
    position: relative;
  }
  .sortasc{
    transform: rotate(180deg);
  }
  .collapsibleContainer {
    /* border: 1px solid #979797; */
    border-radius: 6px;
    padding: 20px;
    background-color: #FFFFFF;
    margin-bottom: 20px;
  }
  .highlightedRow{
    width: 3px;
    height: 57.5px;
    display: inline-block;
    position: absolute;
    line-height: -194px;
    left: -18px;
    top: -16.5px;
    background-color: #E60000;
    z-index: 99999;
  }
  .margingleft{
    margin-left: 10px;
  }

.posrelative{
  position: relative;
}