/*CSS*/

.headContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /* margin-top: 24px; */
}
.container {
    overflow-x: hidden;
    border-radius: 6px;
    border: 1px solid #979797; 
    padding: 10px;   /*padding: 20px; */
    gap: 16px; 
    background-color: white;
    margin-bottom: 20px;
}
.twoservices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Ensure items wrap if they exceed the container width */
}

.twoservice {
    margin-right: 10px; /* Add some space between items */
}
.parentDiv{
    display: flex;
    flex:4;
    margin-bottom: 0px;
}
.wrapper{
display: flex;
flex-direction: row;
justify-content: space-between;
align-self: normal;
/* border-top: 1px solid #E6E6E6; */
padding-top: 10px;
}
/* .container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 25px;
    min-height: 95px;
    overflow-x: hidden;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #979797;
    border-radius: 6px;
} */
.containerheader {
    display: flex; 
    justify-content: space-between;
    align-items: top;
    margin-bottom: 10px; 
}
.headData{
display: flex;
flex-direction: column;
flex:1;
}
.search{
margin-top: -25px;
}
.sub{
    color: #000000;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
}
.headSub{
    color: #666666;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
}
.text{
font-family: 'VodafoneRg-Regular';
font-style: normal;
font-weight: 400;
font-size: 20px;
color: #E60000;
border-bottom: 1px solid #E60000;
}
.link{
    text-decoration: none;
}
.customDataContent{
    display: flex;
    margin-left: 10px;
    font-family: "VodafoneRg-Regular";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
    font-weight: 400;
  }

  .organisationName{
    width: 100%;
    position: relative;
    display: flex;
    margin-left: 10px;
  }

.orglgname > span > a{
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 0%;
    color: #E60000;
    cursor: pointer;
    margin-left: 10px;
}

  .orgname{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: "VodafoneRg-Regular";
    font-weight: 700;
    font-size: 16px;
    /* line-height: 100%; */
    letter-spacing: 0px;
  }

  .filter{
    position: absolute;
    right: 0;
    bottom: -4px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  .sorting{
    position: absolute;
    right: 0;
    bottom: -4px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  .asc{
    transform: rotate(180deg);
  }
  .wrapper{
    position: relative;
  }
  .fincainfo{
    color: #333333;
    font-size: 16px;
    font-family: "VodafoneRg-Regular";
    font-weight: 400;
    line-height: 18.37px;
    border-radius: 6px;
    background-color: #DCDCDC;
    margin-bottom: 24px;
    gap: 5px;
    padding: 4px 8px;
  }
  
  .circleinfo{
    height: 20px;
    width: 20px;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 2px;
  }

  .wrapperchild{
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 24px 0px
  }